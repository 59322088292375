import { AuthContext } from "dashboard-common";
import * as React from "react";
import { useContext } from "react";
import { Content, H1, Skeleton, useDocumentTitle } from "ui-components";

import OrganizationList from "../OrganizationsList/OrganizationList";

export default function ProfileView() {
  const { user } = useContext(AuthContext);
  useDocumentTitle("Profile");
  return (
    <Content>
      <div className="mb-8">
        <H1>Profile</H1>
      </div>
      {!user && (
        <Skeleton className="mb-12 p-6 max-w-none rounded-md relative h-60" />
      )}
      {user && (
        <div className="mb-4 p-6 max-w-none rounded-md relative border border-border h-30">
          <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
            <dt>Name:</dt>
            <dd>{user.displayName}</dd>
          </dl>
          <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
            <dt>Email:</dt>
            <dd>{user.email}</dd>
          </dl>
          <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
            <dt>User Id:</dt>
            <dd>{user.uid}</dd>
          </dl>
        </div>
      )}
      <div className="mt-4">
        <OrganizationList />
      </div>
    </Content>
  );
}
