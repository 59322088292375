import { AuthContext } from "dashboard-common";
import { ListOrganizationsSuccessResponse } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  formatDataTestIdComponent,
  H2,
  ItemPagination,
  ListElement,
  Sonner,
  Title,
  useDataFetcherState,
} from "ui-components";
import { ListElementSkeleton } from "ui-components/src";
import { Link, useLocation } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";
import CreateOrganizationModal from "../ProfileView/CreateOrganizationModal";

export default function OrganizationList() {
  const [, setLocation] = useLocation();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListOrganizationsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchOrganizations = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listOrganizations({
        parameters: {
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, currentPage, dataFetcher]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations, currentPage, itemsPerPage]);

  return (
    <>
      <Title className="pt-8">
        <div className="flex gap-2 items-baseline">
          <H2>Your Organizations</H2>
          <DocsLink path="/organizations/organization" text="Docs" />
        </div>
        <CreateOrganizationModal
          onOrganizationCreated={(organization) => {
            Sonner.toast("Organization created");
            setLocation(`/organizations/${organization.id}`);
          }}
        />
      </Title>
      <div className="w-full min-h-full mx-auto pb-20">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="relative pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the organizations: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchOrganizations();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.organizations.map((organization) => (
              <ListElement
                data-testid={`organisation-link-${formatDataTestIdComponent(organization.name)}`}
                key={organization.id}
                to={`/organizations/${organization.id}`}
                LinkElement={Link}
              >
                <div className="flex-col flex text-sm basis-1/3">
                  Name:
                  <span className="text-xl font-semibold">
                    {organization.name}
                  </span>
                </div>
                <div className="flex-col flex text-sm basis-1/3">
                  ID:
                  <span className="text-xl font-semibold">
                    {organization.id}
                  </span>
                </div>
              </ListElement>
            ))}
            {dataFetcher.data && !dataFetcher.data.organizations.length && (
              <EmptyState message="There are no organizations" />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
