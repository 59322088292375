import { AuthContext } from "dashboard-common";
import { Member, OrganizationRole } from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  BreadCrumbs,
  ErrorAlert,
  MenuBar,
  MenuItem,
  useDataFetcherState,
} from "ui-components";
import { H2 } from "ui-components/src/components/ui";
import { Link, Route, useLocation, useParams } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import { MemberInfo } from "./MemberInfo";
import { MemberPermissionsListView } from "./MemberPermissionsListView/MemberPermissionsListView";

type MemberDashboardProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function MemberView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: MemberDashboardProps) {
  const [location] = useLocation();
  const splitLocation = location.split("/").filter((pathItem) => !!pathItem);
  const { memberId } =
    useParams<"/organizations/:organizationId/members/:memberId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const currentLocation = splitLocation[splitLocation.length - 1];

  const dataFetcher = useDataFetcherState<Member>();

  const fetchMembers = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getMember({
        parameters: {
          memberId,
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          console.log(response);
          dataFetcher.setData(response.body);
        } else {
          console.error("Failed to fetch members");
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      })
      .finally(() => dataFetcher.setIsLoading(false));
  }, [getAccessToken, onUnauthorized, organizationId, dataFetcher, memberId]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const member = dataFetcher.data;

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "Members",
      to: `/organizations/${organizationId}/members/`,
    },
    {
      name: member?.userInfo.displayName ?? "Member",
    },
  ];

  return (
    <div className="w-full mx-auto mt-10">
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      {dataFetcher.error && (
        <div className="w-full relative">
          {dataFetcher.error.code === 404 ? (
            <ErrorAlert
              message="Member not found"
              link={{
                to: `/organizations/${organizationId}/members/`,
                text: "Go Back",
                element: Link,
              }}
            />
          ) : (
            <ErrorAlert
              message={`There was a problem fetching the member: ${dataFetcher.error.message}`}
              action={{
                text: "Retry",
                cb: () => {
                  fetchMembers();
                },
              }}
            />
          )}
        </div>
      )}
      {!dataFetcher.error && (
        <>
          {dataFetcher.isLoading ? (
            <div className="mb-10">
              <H2>Loading...</H2>
            </div>
          ) : (
            <>
              <MenuBar className="mb-10">
                <MenuItem
                  active={!["members", "permissions"].includes(currentLocation)}
                  to={`/organizations/${organizationId}/members/${memberId}`}
                  LinkElement={Link}
                >
                  Info
                </MenuItem>
                <MenuItem
                  active={currentLocation === "permissions"}
                  to={`/organizations/${organizationId}/members/${memberId}/permissions`}
                  LinkElement={Link}
                >
                  Permissions
                </MenuItem>
              </MenuBar>
              <Route path={"/organizations/:organizationId/members/:memberId/"}>
                <MemberInfo
                  member={member}
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationRole!}
                />
              </Route>
              <Route
                path={
                  "/organizations/:organizationId/members/:memberId/permissions"
                }
              >
                <MemberPermissionsListView
                  memberId={memberId}
                  member={member}
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationRole!}
                />
              </Route>
            </>
          )}
        </>
      )}
    </div>
  );
}
