import { ListMembersSuccessResponse } from "identity-api";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  buttonVariants,
  cn,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useDataFetcherState,
} from "ui-components";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type SelectMemberInputProps = {
  value: string;
  onChange: (value: string) => void;
  getAccessToken: () => Promise<string>;
  onUnauthorized: () => void;
  organizationId: string;
};

function SelectMemberInput({
  value,
  onChange,
  getAccessToken,
  onUnauthorized,
  organizationId,
}: SelectMemberInputProps) {
  const [open, setOpen] = useState(false);
  const dataFetcherOrganizationsMembers =
    useDataFetcherState<ListMembersSuccessResponse["body"]>();

  const fetchOrganizationMembers = useCallback(() => {
    dataFetcherOrganizationsMembers.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listMembers({
        parameters: {
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcherOrganizationsMembers.setData(response.body);
        } else {
          dataFetcherOrganizationsMembers.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcherOrganizationsMembers.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    organizationId,
    dataFetcherOrganizationsMembers,
  ]);

  useEffect(() => {
    fetchOrganizationMembers();
  }, [fetchOrganizationMembers]);

  const currentMember = dataFetcherOrganizationsMembers.data?.members.find(
    (member) => member.id === value,
  );

  const selectedMemberName = currentMember?.userInfo.displayName;

  const handleMemberClick = (id: string) => {
    const memberToSet = dataFetcherOrganizationsMembers.data?.members.find(
      (p) => id === p.id,
    );
    if (!memberToSet) {
      return;
    }

    onChange(memberToSet.id);
    setOpen(false);
  };

  if (dataFetcherOrganizationsMembers.isLoading) {
    return (
      <div
        className={cn(
          buttonVariants(),
          "min-h-0 h-10 w-full min-w-40 rounded-lg px-10 opacity-30",
        )}
      >
        Loading...
      </div>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="min-h-0 h-10 w-full min-w-40 rounded-lg px-10">
          {selectedMemberName ?? "Please select a member..."}
          <ChevronDown />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Member</DialogTitle>
        </DialogHeader>
        <div>
          <ul className="flex flex-col gap-2">
            {dataFetcherOrganizationsMembers.data?.members.map((member) => (
              <li key={member.id}>
                <Button
                  key={member.id}
                  className="w-full"
                  onClick={() => handleMemberClick(member.id)}
                >
                  {member.userInfo.displayName}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SelectMemberInput;
