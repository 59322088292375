import { useContext } from "react";

import { SessionContext } from "./context";
import { SessionContextValue } from "./types";

export function useSession(): SessionContextValue {
  if (SessionContext === undefined) {
    throw new Error("SessionContext is not defined");
  }

  const value = useContext(SessionContext);
  if (!value && process.env.NODE_ENV !== "production") {
    throw new Error("`useSession` must be wrapped in a <SessionProvider />");
  }

  return value as SessionContextValue;
}
