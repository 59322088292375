import { AuthContext } from "dashboard-common";
import {
  ListTeamMembersSuccessResponse,
  OrganizationRole,
  OrganizationRoleAdmin,
  Team,
  TeamMember,
  TeamRoleAdmin,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Avatar,
  AvatarFallback,
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { Link } from "wouter";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import CreateTeamMemberModal from "./CreateTeamMemberModal";
import DeleteTeamMemberModal from "./DeleteTeamMemberModal";
import EditMemberModal from "./EditTeamMemberModal";

type TeamMembersListViewProps = {
  teamId: string;
  organizationId: string;
  organizationDisplayName: string;
  team: Team | null;
  organizationRole: OrganizationRole;
};

export function TeamMembersListView({
  teamId,
  organizationId,
  organizationDisplayName,
  team,
  organizationRole,
}: TeamMembersListViewProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListTeamMembersSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchTeamMembers = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listMembersForTeam({
        parameters: {
          organizationId,
          teamId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    teamId,
    currentPage,
    itemsPerPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  useDocumentTitle(
    `Team Members - ${team?.name || "Loading..."} - ${organizationDisplayName}`,
  );

  const { teamRole = null } = dataFetcher.data || {};

  return (
    <>
      <Title>
        <div className="flex gap-2 items-baseline">
          <H2>{team ? `Team ${team?.name}` : "Loading"}</H2>
          <DocsLink path="/organizations/team/team-members" text="Docs" />
        </div>
        <CreateTeamMemberModal
          teamId={teamId}
          organizationId={organizationId}
          onTeamMemberCreated={() => {
            fetchTeamMembers();
            Sonner.toast("Team Member Added");
          }}
          disabled={
            organizationRole !== OrganizationRoleAdmin &&
            teamRole !== TeamRoleAdmin
          }
        />
      </Title>
      <div className="w-full min-h-full mx-auto pb-20 relative">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="pt-20"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the team members: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchTeamMembers();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.teamMembers.map((teamMember: TeamMember) => (
              <ListElement
                key={teamMember.id}
                to={`/organizations/${organizationId}/members/${teamMember.userId}/`}
                LinkElement={Link}
              >
                <Avatar className="mr-4">
                  <AvatarFallback>
                    {teamMember?.userInfo.displayName?.[0] ?? ""}
                  </AvatarFallback>
                </Avatar>
                <div className="flex-col flex text-sm basis-1/5">
                  Name:
                  <span className="text-xl font-semibold">
                    {teamMember.userInfo.displayName}
                  </span>
                </div>
                <div className="flex-col flex text-sm">
                  Role:
                  <span className="text-xl font-semibold">
                    {teamMember.role}
                  </span>
                </div>
                {[organizationRole, teamRole].includes("admin") && (
                  <div className="ml-auto flex gap-2 mr-4">
                    <EditMemberModal
                      teamId={teamId}
                      member={teamMember}
                      organizationId={organizationId}
                      onMemberUpdated={() => {
                        fetchTeamMembers();
                        Sonner.toast("Team Member updated");
                      }}
                    />
                    <DeleteTeamMemberModal
                      teamId={teamId}
                      organizationId={organizationId}
                      memberId={teamMember.id}
                      onTeamMemberDeleted={() => {
                        fetchTeamMembers();
                        Sonner.toast("Team Member deleted");
                      }}
                    />
                  </div>
                )}
              </ListElement>
            ))}
            {dataFetcher.data && !dataFetcher.data.teamMembers.length && (
              <EmptyState message="There are no team members." />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
