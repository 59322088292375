import { AuthContext } from "dashboard-common";
import { ListProjectsSuccessResponse, Project } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  Sonner,
  Title,
  TooltipProvider,
  useDataFetcherState,
} from "ui-components";
import { ListElementSkeleton } from "ui-components/src";
import { Link, useLocation } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";
import CreateProjectModal from "./CreateProjectModal";

export function ProjectsList() {
  const [, setLocation] = useLocation();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListProjectsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchProjects = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listVisibleProjects({
        parameters: {
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, currentPage, onUnauthorized, dataFetcher]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects, currentPage, itemsPerPage]);

  return (
    <>
      <TooltipProvider>
        <Title className="pt-8">
          <div className="flex gap-2 items-baseline">
            <H2>Your Projects</H2>
            <DocsLink path="/organizations/projects" text="Docs" />
          </div>
          <CreateProjectModal
            onProjectCreated={(project) => {
              Sonner.toast("Project created");
              setLocation(`/projects/${project.id}/`);
            }}
            disabled={false}
          />
        </Title>
      </TooltipProvider>
      <div className="w-full min-h-full mx-auto pb-20">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="relative pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the projects: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchProjects();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data &&
              dataFetcher.data.projects.map((project: Project) => (
                <ListElement
                  key={project.id}
                  to={`/projects/${project.id}/`}
                  LinkElement={Link}
                >
                  <div className="flex-col flex text-sm basis-2/5">
                    Project name:
                    <span className="text-xl font-semibold">
                      {project.name}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm">
                    Project ID:
                    <span className="text-xl font-semibold">{project.id}</span>
                  </div>
                </ListElement>
              ))}
            {dataFetcher.data && !dataFetcher.data.projects.length && (
              <EmptyState message="There are no projects" />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
