import { AuthContext } from "dashboard-common";
import { ListProjectsSuccessResponse, Project } from "identity-api";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  ErrorAlert,
  Skeleton,
  useDataFetcherState,
} from "ui-components";
import { DialogTrigger } from "ui-components/src";
import { useLocation } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";

type SelectProjectModalProps = {
  projectId: string;
};

function SelectProjectModal({ projectId }: SelectProjectModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const dataFetcher =
    useDataFetcherState<ListProjectsSuccessResponse["body"]>();

  const currentProjectDataFetcher = useDataFetcherState<Project>();

  const [, setLocation] = useLocation();

  const handleProjectClick = (id: string) => {
    setLocation(`/projects/${id}/`);
    setOpen(false);
  };

  const fetchProjects = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listVisibleProjects({
        parameters: {},
        body: null,
      })
      .then((response) => {
        if (!open) {
          return;
        }
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [open, getAccessToken, onUnauthorized, dataFetcher]);

  useEffect(() => {
    if (open) {
      fetchProjects();
    } else {
      dataFetcher.setData({
        projects: [],
        totalResults: 0,
        offset: 0,
        limit: 0,
      });
    }
  }, [fetchProjects, open, dataFetcher]);

  const fetchCurrentProject = useCallback(() => {
    currentProjectDataFetcher.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getProject({
        parameters: {
          projectId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          currentProjectDataFetcher.setData(response.body);
        } else {
          currentProjectDataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        currentProjectDataFetcher.setError(err);
      });
  }, [projectId, getAccessToken, onUnauthorized, currentProjectDataFetcher]);

  useEffect(() => {
    fetchCurrentProject();
  }, [fetchCurrentProject, projectId]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="min-h-0 h-8 min-w-40 max-w-100 rounded-lg px-4 relative">
          {currentProjectDataFetcher.data
            ? `${currentProjectDataFetcher.data.name} (${projectId})`
            : `Loading... ${projectId}`}
          <ChevronDown />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Project</DialogTitle>
        </DialogHeader>
        <div>
          {dataFetcher.isLoading && (
            <ul className="flex flex-col gap-2">
              {Array.from(Array(3).keys()).map((i) => (
                <Skeleton
                  key={i}
                  className="w-full flex flex-col items-start py-8"
                />
              ))}
            </ul>
          )}
          {dataFetcher.error && (
            <ErrorAlert
              className="top-0"
              message={`There was a problem fetching the projects: ${dataFetcher.error.message}`}
              action={{
                text: "Retry",
                cb: () => {
                  fetchProjects();
                },
              }}
            />
          )}
          {dataFetcher.data && (
            <ul className="flex flex-col gap-2">
              {dataFetcher.data.projects.map((project) => (
                <li key={project.id}>
                  <Button
                    key={project.id}
                    className="w-full flex flex-col items-start py-8"
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <span className="text-lg font-semibold">
                      {project.name}
                    </span>
                    <span>Project ID: {project.id}</span>
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SelectProjectModal;
