import { AuthContext } from "dashboard-common";
import {
  APIKey,
  ListAPIKeysSuccessResponse,
  OrganizationRole,
  OrganizationRoleAdmin,
} from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  BreadCrumbs,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { Link, useLocation } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import APIKeyCreatedModal from "./APIKeyCreatedModal";
import CreateAPIKeyModal from "./CreateAPIKeyModal";
import DeleteAPIKeyModal from "./DeleteAPIKeyModal";

type APIKeyListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function APIKeysListView({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: APIKeyListViewProps) {
  const [, setLocation] = useLocation();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher = useDataFetcherState<ListAPIKeysSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [createdAPIKey, setCreatedAPIKey] = useState<{
    apiKey: APIKey;
    apiKeyToken: string;
  } | null>(null);

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "API Keys",
    },
  ];

  const fetchAPIKeys = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listAPIKeys({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    if (organizationRole === OrganizationRoleAdmin) {
      fetchAPIKeys();
    } else {
      dataFetcher.setIsLoading(false);
    }
  }, [fetchAPIKeys, organizationRole, dataFetcher]);

  useDocumentTitle(`API Keys - ${organizationDisplayName}`);

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      {createdAPIKey && (
        <APIKeyCreatedModal
          apiKeyToken={createdAPIKey.apiKeyToken}
          onClose={() => {
            setCreatedAPIKey(null);
            fetchAPIKeys();
          }}
          onNavigateTo={() => {
            const { apiKey } = createdAPIKey;
            setLocation(
              `/organizations/${organizationId}/api-keys/${apiKey.id}`,
            );
          }}
        />
      )}
      <div className="max-w-none relative">
        <Title>
          <div className="flex gap-2 items-baseline">
            <H2>API Keys</H2>
            <DocsLink path="/organizations/api-keys" text="Docs" />
          </div>
          <CreateAPIKeyModal
            organizationId={organizationId}
            onAPIKeyCreated={(createdAPIKey) => {
              Sonner.toast("API Key created");
              fetchAPIKeys();
              setCreatedAPIKey(createdAPIKey);
            }}
            disabled={organizationRole !== OrganizationRoleAdmin}
          />
        </Title>
      </div>
      <div className="w-full mx-auto pb-20">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="relative pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage} // number of items per page
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                className="top-0"
                message={`There was a problem fetching the API keys: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchAPIKeys();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.apiKeys.map((apiKey) => (
              <ListElement
                key={apiKey.id}
                to={`/organizations/${organizationId}/api-keys/${apiKey.id}`}
                LinkElement={Link}
              >
                <div className="flex-col flex text-sm basis-1/5">
                  Key name:
                  <span className="text-xl font-semibold">{apiKey.name}</span>
                </div>
                <div className="flex-col flex text-sm basis-2/5">
                  APIKey ID:
                  <span className="text-xl font-semibold">{apiKey.id}</span>
                </div>
                <div className="ml-auto flex gap-2 mr-4">
                  <DeleteAPIKeyModal
                    apiKeyId={apiKey.id}
                    organizationId={organizationId}
                    onAPIKeyDeleted={() => {
                      fetchAPIKeys();
                      Sonner.toast("API Key deleted");
                    }}
                  />
                </div>
              </ListElement>
            ))}
            {dataFetcher.data && !dataFetcher.data.apiKeys.length && (
              <div className="text-center w-full">
                <EmptyState message="There are no API keys" />
              </div>
            )}
            {organizationRole !== OrganizationRoleAdmin && (
              <div className="text-center w-full">
                <EmptyState message="You don't have permission to view API keys for this organization" />
              </div>
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
