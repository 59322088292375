import {
  Organization,
  OrganizationRole,
  OrganizationRoleAdmin,
  Project,
} from "identity-api";
import * as React from "react";
import {
  BreadCrumbs,
  ErrorAlert,
  ErrorContents,
  H1,
  H2,
  Skeleton,
  Sonner,
  TooltipProvider,
  useDocumentTitle,
} from "ui-components";
import { Link } from "wouter";

import { apps } from "../../apps";
import EditProjectModal from "./EditProjectModal";

type ProjectInfoProps = {
  project: Project & { organization: Organization };
  isLoading: boolean;
  error: ErrorContents | null;
  onRetry: () => void;
  onUpdateProject: (project: Project) => void;
  organizationRole?: OrganizationRole;
};

export default function ProjectInfo({
  project,
  isLoading,
  error,
  onRetry,
  onUpdateProject,
  organizationRole,
}: ProjectInfoProps) {
  const breadCrumbPath = [
    {
      name: project?.name || "Project",
    },
  ];

  useDocumentTitle(`Project - ${isLoading ? "loading" : project?.name}`);

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      <div className="mb-8">
        <H1>Dashboard</H1>
      </div>
      {error && (
        <div className="w-full relative">
          <ErrorAlert
            message={error.message}
            action={{
              text: "Retry",
              cb: () => onRetry(),
            }}
          />
        </div>
      )}
      {isLoading && (
        <Skeleton className="mb-12 p-6 max-w-none rounded-md relative h-60" />
      )}
      {project && (
        <>
          <div className="mb-12 p-6 max-w-none rounded-md relative border border-border h-60">
            <TooltipProvider>
              <EditProjectModal
                project={project!}
                updateProject={(updatedProject) => {
                  onUpdateProject(updatedProject);
                  Sonner.toast("Project updated");
                }}
                disabled={organizationRole !== OrganizationRoleAdmin}
              />
              <div className="flex justify-between items-center mb-4">
                <H2 className="font-bold m-0">
                  <span data-testid="dashboard-project-name">
                    {project.name}
                  </span>
                </H2>
              </div>
              <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
                <dt>Project Id:</dt>
                <dd>{project?.id}</dd>
              </dl>
              <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
                <dt>Organization Name:</dt>
                <dd>
                  <Link
                    className="underline"
                    to={`/organizations/${project.organizationId}`}
                  >
                    {project?.organization?.name}
                  </Link>
                </dd>
              </dl>
              <dl className="flex items-end m-0 *:m-0 *:p-0 gap-2">
                <dt>Organization Id:</dt>
                <dd>
                  <Link
                    data-testid="project-info-organisation-link"
                    className="underline"
                    to={`/organizations/${project.organizationId}`}
                  >
                    {project?.organizationId}
                  </Link>
                </dd>
              </dl>
            </TooltipProvider>
          </div>
          {apps.map((section, index) => {
            return (
              <div key={index}>
                <H2>{section.name}</H2>
                <div className="grid grid-cols-4 gap-4 mt-4">
                  {section.items.map((app) => {
                    const Icon = app.icon;
                    return (
                      <Link
                        className="w-full h-40 mt-2 rounded-lg flex flex-col items-center text-xl border border-border justify-center"
                        to={`/projects/${project?.id}/${app.path}/`}
                        key={app.path}
                      >
                        <Icon size={48} />
                        <div className="font-semibold mt-2">{app.name}</div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
