import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { Project } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import * as z from "zod";

import { createIdentityServiceClient } from "../../IdentityServiceClient";
import SelectOrganizationInput from "../SelectOrganizationInput/SelectOrganizationInput";

type CreateProjectModalProps = {
  withinOrganizationId?: string;
  onProjectCreated: (project: Project) => void;
  disabled: boolean;
};

const createProject = (
  projectName: string,
  organizationId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createProject({
      parameters: {
        organizationId,
      },
      body: {
        name: projectName,
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create project");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  projectName: z.string().min(1, { message: "Name is required" }),
  organizationId: z.string().min(1, { message: "Organization id is required" }),
});

function CreateProjectModal({
  onProjectCreated,
  withinOrganizationId,
  disabled,
}: CreateProjectModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const project = await createProject(
        data.projectName,
        data.organizationId,
        getAccessToken,
        onUnauthorized,
      );

      onProjectCreated(project);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg !pointer-events-auto"
            disabled={disabled}
          >
            Create Project
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Project
          </DialogTitle>
        </DialogHeader>
        <CreateProjectModalForm
          error={error}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          withinOrganizationId={withinOrganizationId}
        />
      </DialogContent>
    </Dialog>
  );
}

function CreateProjectModalForm({
  error,
  onSubmit,
  isSubmitting,
  withinOrganizationId,
}: {
  error: Error | null;
  onSubmit: (data: z.infer<typeof formSchema>) => void;
  isSubmitting: boolean;
  withinOrganizationId?: string;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    defaultValues: {
      projectName: "",
      organizationId: withinOrganizationId || "",
    },
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="projectName"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="project-name">
                Project Name
              </Label>
              <Input
                id="project-name"
                type="text"
                placeholder="Project Name"
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="organizationId"
          defaultValue={withinOrganizationId}
          render={({ field }) => (
            <FormItem className="w-full mt-2">
              <Label className="block mb-2" htmlFor="project-name">
                Organization
              </Label>
              {withinOrganizationId ? (
                <Input
                  type={"text"}
                  value={withinOrganizationId}
                  disabled={true}
                />
              ) : (
                <SelectOrganizationInput
                  value={field.value}
                  onChange={(orgId) => field.onChange(orgId)}
                />
              )}
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the project:</div>
              <div data-testid="create-project-error">{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default CreateProjectModal;
