import { AuthContext } from "dashboard-common";
import * as React from "react";
import { useContext } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  H3,
} from "ui-components";
import { Link } from "wouter";

import { useSession } from "../../../auth/useSession";

export function Profile() {
  const { signOut } = useSession();
  const { user } = useContext(AuthContext);
  if (!user) {
    return null;
  }

  return (
    <div className="flex justify-center ml-5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="icon" className="rounded-full">
            <Avatar>
              <AvatarImage
                src={user?.photoURL ?? undefined}
                alt={user?.displayName ?? undefined}
              />
              <AvatarFallback>
                {user?.displayName?.[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[1]">
          <DropdownMenuLabel>
            <H3>
              {user?.displayName} ({user.email})
            </H3>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <Link href="/profile">
            <DropdownMenuItem>Account & Settings</DropdownMenuItem>
          </Link>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => signOut()}
          >
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
