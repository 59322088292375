import * as React from "react";
import { buttonVariants, cn } from "ui-components";

import SelectProjectModal from "../SelectProjectModal/SelectProjectModal";
import SendFeedback from "../SendFeedbackModal/SendFeedbackModal";
import ThemeToggle from "../ThemeToggle";
import { LogoHeader } from "./LogoHeader";
import { Profile } from "./Profile/Profile";

type TopNavProps = {
  projectId?: string;
};

export function TopNav({ projectId }: TopNavProps) {
  return (
    <>
      <div className="h-navbar-height min-h-0 flex justify-between px-5 items-center border-b-border border-b relative">
        <div className="flex justify-center items-center gap-4">
          <LogoHeader />
          {projectId && <SelectProjectModal projectId={projectId} />}
        </div>
        <div className="flex justify-between items-center">
          <a
            href="https://docs.mserve.io"
            target="_blank"
            className={cn(
              buttonVariants({ variant: "outline" }),
              "mr-4 text-blue-500",
            )}
            rel="noreferrer"
          >
            Docs
          </a>
          <SendFeedback />
          <ThemeToggle />
          <Profile />
        </div>
      </div>
    </>
  );
}
