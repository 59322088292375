import { AuthContext } from "dashboard-common";
import { Organization, OrganizationRole } from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  Content,
  ErrorAlert,
  formatDataTestIdComponent,
  H1,
  NotFound,
  TooltipProvider,
  useDataFetcherState,
} from "ui-components";
import { Link, Route, Switch, useParams } from "wouter";

import { createIdentityServiceClient } from "../../IdentityServiceClient";
import { APIKeyDashboard } from "./APIKeyDashboard/APIKeyDashboard";
import { APIKeysListView } from "./APIKeysListView/APIKeysListView";
import { InvitesListView } from "./InvitesListView/InvitesListView";
import { MembersListView } from "./MembersListView/MembersListView";
import { MemberView } from "./MemberView/MemberView";
import { OrganizationInfo } from "./OrganizationInfo";
import { OrganizationsNav } from "./OrganizationsNav";
import { ProjectsListView } from "./ProjectsListView/ProjectsListView";
import { TeamDashboard } from "./TeamDashboard/TeamDashboard";
import { TeamsListView } from "./TeamsListView/TeamsListView";

export default function OrganizationDashboard() {
  const { organizationId } = useParams<"/organizations/:organizationId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const dataFetcher = useDataFetcherState<{
    organization: Organization;
    organizationMembership?: {
      role: OrganizationRole;
    };
  }>();

  const fetchOrganization = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getOrganization({
        parameters: {
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, organizationId, dataFetcher]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const organization = dataFetcher.data?.organization || null;
  const organizationMembership =
    dataFetcher.data?.organizationMembership || null;
  const organizationDisplayName = dataFetcher.data
    ? dataFetcher.data.organization.name
    : dataFetcher.isLoading
      ? "Loading..."
      : "Error";

  if (organization && !organizationMembership) {
    return <ErrorAlert message="An unknown error occurred" />;
  }

  return (
    <>
      {dataFetcher.error?.code !== 404 && (
        <OrganizationsNav
          orgId={organizationId}
          orgName={
            dataFetcher.isLoading
              ? "Fetching Info..."
              : (dataFetcher.data?.organization.name as string)
          }
        />
      )}
      <Content>
        <TooltipProvider>
          {dataFetcher.isLoading && (
            <div className="mb-10">
              <H1>Loading...</H1>
            </div>
          )}
          {organization && (
            <div className="mb-10">
              <H1
                data-testid={`organization-${formatDataTestIdComponent(organization ? organization.name : "")}-title`}
              >
                Organization: {organization?.name}
              </H1>
            </div>
          )}
          {dataFetcher.error?.code === 404 && (
            <div className="mb-10 relative w-full">
              <ErrorAlert
                message="Organization not found"
                link={{
                  to: "/organizations",
                  text: "Go Back",
                  element: Link,
                }}
              />
            </div>
          )}
          {dataFetcher.error?.code !== 404 && (
            <Switch>
              <Route path={"/organizations/:organizationId/"}>
                <OrganizationInfo
                  organization={organization}
                  organizationRole={
                    organizationMembership?.role as OrganizationRole
                  }
                  loading={dataFetcher.isLoading}
                  error={dataFetcher.error}
                  updateOrganization={(updatedOrganization) => {
                    dataFetcher.setData({
                      ...dataFetcher.data,
                      organization: updatedOrganization,
                    });
                  }}
                  onRetry={fetchOrganization}
                />
              </Route>
              <Route path={"/organizations/:organizationId/projects"}>
                <ProjectsListView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route
                path={"/organizations/:organizationId/members/:memberId/*?"}
              >
                <MemberView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route path={"/organizations/:organizationId/teams/:teamId/*?"}>
                <TeamDashboard
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route path={"/organizations/:organizationId/teams"}>
                <TeamsListView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route path={"/organizations/:organizationId/members"}>
                <MembersListView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route path={"/organizations/:organizationId/api-keys"}>
                <APIKeysListView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route
                path={"/organizations/:organizationId/api-keys/:apiKeyId/*?"}
              >
                <APIKeyDashboard
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={organizationMembership?.role}
                />
              </Route>
              <Route path={"/organizations/:organizationId/invites"}>
                <InvitesListView
                  organizationId={organizationId}
                  organizationDisplayName={organizationDisplayName}
                  organizationRole={
                    organizationMembership?.role as OrganizationRole
                  }
                />
              </Route>
              <Route path={"/organizations/:organizationId/*"}>
                <NotFound />
              </Route>
            </Switch>
          )}
        </TooltipProvider>
      </Content>
    </>
  );
}
