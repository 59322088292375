import { AuthContext } from "dashboard-common";
import { Trash } from "lucide-react";
import * as React from "react";
import { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "ui-components";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";

type DeleteTeamPermissionModalProps = {
  permissionId: string;
  teamId: string;
  organizationId: string;
  withinOrganizationId?: string;
  onTeamPermissionDeleted: () => void;
};

const deleteTeamPermission = (
  teamId: string,
  organizationId: string,
  permissionId: string,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .deleteTeamPermission({
      parameters: {
        organizationId,
        teamId,
        permissionId,
      },
      body: null,
    })
    .then((response) => {
      if (response.code === 204) {
        return response.body;
      } else {
        console.error("Failed to delete permission");
        throw new Error(response.body.message);
      }
    });

function DeleteTeamPermissionModal({
  permissionId,
  teamId,
  organizationId,
  onTeamPermissionDeleted,
}: DeleteTeamPermissionModalProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function onSubmit() {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      await deleteTeamPermission(
        teamId,
        organizationId,
        permissionId,
        getAccessToken,
        onUnauthorized,
      );

      onTeamPermissionDeleted();
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button size="icon" className="rounded-lg">
            <Trash />
          </Button>
        </DialogTrigger>
        <DialogContent className={error ? "border-destructive" : ""}>
          <DialogHeader>
            <DialogTitle className={error ? "text-destructive" : ""}>
              Delete Team Permission
            </DialogTitle>
          </DialogHeader>
          <DialogFooter className="mt-4">
            {error && (
              <p className="flex-1 text-destructive self-center">
                <div>There was a problem deleting the permission:</div>
                <div>{error.message}</div>
              </p>
            )}
            <Button
              disabled={isSubmitting}
              variant={error ? "destructive" : "default"}
              onClick={onSubmit}
            >
              {isSubmitting ? "Deleting..." : error ? "Retry" : "Delete"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteTeamPermissionModal;
