import { AuthContext } from "dashboard-common";
import {
  APIKey,
  APIKeyPermission,
  ListAPIKeyPermissionsSuccessResponse,
} from "identity-api";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BasicList,
  DocsLink,
  EmptyState,
  ErrorAlert,
  H2,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import { permissionResourceToDisplayName } from "../../permissionResources";
import CreateAPIKeyPermissionModal from "./CreateAPIKeyPermissionModal";
import DeleteAPIKeyPermissionModal from "./DeleteAPIKeyPermissionModal";

type APIKeyPermissionsListViewProps = {
  apiKeyId: string;
  organizationId: string;
  organizationDisplayName: string;
  apiKey: APIKey | null;
};

export function APIKeyPermissionsListView({
  apiKeyId,
  organizationId,
  organizationDisplayName,
  apiKey,
}: APIKeyPermissionsListViewProps) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const dataFetcher =
    useDataFetcherState<ListAPIKeyPermissionsSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fetchAPIKeyPermissions = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listPermissionsForAPIKey({
        parameters: {
          organizationId,
          apiKeyId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    apiKeyId,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchAPIKeyPermissions();
  }, [fetchAPIKeyPermissions]);

  useDocumentTitle(
    `API Key Permissions - ${apiKey?.name || "Loading..."} - ${organizationDisplayName}`,
  );

  return (
    <div className="mt-4">
      <Title>
        <div className="flex gap-2 items-baseline">
          <H2>Permissions</H2>
          <DocsLink
            path="/organizations/api-keys/api-key-permissions"
            text="Docs"
          />
        </div>
        <CreateAPIKeyPermissionModal
          apiKeyId={apiKeyId}
          organizationId={organizationId}
          onAPIKeyPermissionCreated={() => {
            fetchAPIKeyPermissions();
            Sonner.toast("API Key Permission created");
          }}
        />
      </Title>
      <div className="w-full min-h-full mx-auto pb-20 relative">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="pt-20"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the API key permissions: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchAPIKeyPermissions();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.apiKeyPermissions.map(
              (apiKeyPermission: APIKeyPermission) => (
                <ListElement key={apiKeyPermission.id} noLink>
                  <div className="flex-col flex text-sm basis-1/3 flex-0 overflow-hidden">
                    Project ID:
                    <span className="text-xl font-semibold truncate">
                      {apiKeyPermission.projectId}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm basis-1/3">
                    Verb:
                    <span className="text-xl font-semibold">
                      {apiKeyPermission.permission.verb}
                    </span>
                  </div>
                  <div className="flex-col flex text-sm basis-1/3">
                    Resource:
                    <span className="text-xl font-semibold">
                      {permissionResourceToDisplayName(
                        apiKeyPermission.permission.resource,
                      )}
                    </span>
                  </div>
                  <div className="ml-auto flex gap-2 mr-4">
                    <DeleteAPIKeyPermissionModal
                      apiKeyId={apiKeyId}
                      organizationId={organizationId}
                      permissionId={apiKeyPermission.id}
                      onAPIKeyPermissionDeleted={() => {
                        fetchAPIKeyPermissions();
                        Sonner.toast("API Key Permission deleted");
                      }}
                    />
                  </div>
                </ListElement>
              ),
            )}
            {dataFetcher.data &&
              !dataFetcher.data?.apiKeyPermissions.length && (
                <EmptyState message="There are no permissions." />
              )}
          </BasicList>
        </ItemPagination>
      </div>
    </div>
  );
}
