import "./index.css";
import "./tailwind.css";

import * as React from "react";
import { useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { Route, Switch } from "wouter";

import App from "./App";
import { SessionProvider } from "./auth/useSession";
import body from "./body.mdx";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const ExternalRedirect = ({ redirectTo }: { redirectTo: string }) => {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return null;
};

root.render(
  <SessionProvider>
    <Switch>
      <Route path={`/privacy`}>
        <ExternalRedirect redirectTo="https://msquared.io/privacy-policy" />
      </Route>
      <Route path={`/terms`}>{body}</Route>
      <Route>
        <App />
      </Route>
    </Switch>
  </SessionProvider>,
);
