import { AuthContext } from "dashboard-common";
import { APIKey, OrganizationRole } from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import { BreadCrumbs, ErrorAlert, useDataFetcherState } from "ui-components";
import { H2 } from "ui-components/src/components/ui";
import { Link, useParams } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import { APIKeyPermissionsListView } from "./APIKeyPermissionsListView/APIKeyPermissionsListView";

type APIKeyDashboardProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function APIKeyDashboard({
  organizationId,
  organizationDisplayName,
}: APIKeyDashboardProps) {
  const { apiKeyId } =
    useParams<"/organizations/:organizationId/teams/:apiKeyId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const dataFetcher = useDataFetcherState<APIKey>();
  const fetchData = useCallback(() => {
    dataFetcher.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getAPIKey({
        parameters: {
          apiKeyId,
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, apiKeyId, organizationId, dataFetcher]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const apiKey = dataFetcher.data;

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "API Keys",
      to: `/organizations/${organizationId}/api-keys`,
    },
    {
      name: apiKey?.name,
    },
  ];

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      <div className="w-full">
        {dataFetcher.isLoading && (
          <div className="mb-20">
            <H2>Loading...</H2>
          </div>
        )}
        {dataFetcher.error && (
          <div className="w-full relative">
            {dataFetcher.error.code === 404 ? (
              <ErrorAlert
                message="API Key not found"
                link={{
                  text: "Go back",
                  to: `/organizations/${organizationId}/api-keys/`,
                  element: Link,
                }}
              />
            ) : (
              <ErrorAlert
                message={dataFetcher.error.message}
                action={{
                  text: "Retry",
                  cb: () => fetchData(),
                }}
              />
            )}
          </div>
        )}
        {apiKey && (
          <>
            <H2 className="font-bold m-0">{apiKey.name}</H2>
            <p>{apiKey.id}</p>
            <p>{apiKey.createdAt}</p>
            <APIKeyPermissionsListView
              apiKeyId={apiKeyId}
              organizationId={organizationId}
              organizationDisplayName={organizationDisplayName}
              apiKey={apiKey}
            />
          </>
        )}
      </div>
    </>
  );
}
