import { Session } from "dashboard-api";
import * as React from "react";
import { useCallback, useEffect } from "react";

import { getCsrfToken, getSession, signOut as libSignOut } from "../lib";
import { AuthEvents, broadcast } from "./broadcast";
import { SessionContext } from "./context";
import { SessionContextValue } from "./types";

type Props = {
  children: React.ReactNode;
};

export function SessionProvider({ children }: Props) {
  if (!SessionContext) {
    throw new Error("React Context is unavailable in Server Components");
  }

  const [session, setSession] = React.useState<Session | null>(null);
  const [loading, setLoading] = React.useState(true);

  const refreshSession = useCallback(async () => {
    setLoading(true);
    const getSessionResult = await getSession();
    if (getSessionResult.type === "error") {
      setSession(null);
      setLoading(false);
      return;
    }

    broadcast.postMessage(AuthEvents.Session);
    setSession(getSessionResult.session);
    setLoading(false);
  }, []);

  const refreshSessionIfExpired = useCallback(async () => {
    if (
      !session ||
      new Date(session.expires).getTime() > new Date().getTime()
    ) {
      return;
    }

    await refreshSession();
  }, [session, refreshSession]);

  const refreshSessionIfMissing = useCallback(async () => {
    if (session) {
      return;
    }

    await refreshSession();
  }, [session, refreshSession]);

  const clearAndRefreshSession = useCallback(async () => {
    setSession(null);
    await refreshSession();
  }, [refreshSession]);

  useEffect(() => {
    refreshSession().then();
  }, []);

  useEffect(() => {
    const visibilityHandler = () => {
      if (document.visibilityState === "visible") {
        refreshSessionIfExpired().then();
      }
    };

    document.addEventListener("visibilitychange", visibilityHandler, false);

    return () =>
      document.removeEventListener(
        "visibilitychange",
        visibilityHandler,
        false,
      );
  }, [refreshSessionIfExpired]);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      switch (event.data) {
        case AuthEvents.SignOut:
          clearAndRefreshSession().then();
          break;
        case AuthEvents.Session:
          refreshSessionIfMissing().then();
          break;
        default:
          console.error(`Received unknown auth message: ${event.data}`);
      }
    };

    broadcast.addEventListener("message", onMessage);

    return () => {
      broadcast.removeEventListener("message", onMessage);
    };
  }, [clearAndRefreshSession, refreshSessionIfMissing]);

  const signOut = useCallback(async () => {
    await libSignOut();
    await clearAndRefreshSession();
    broadcast.postMessage(AuthEvents.SignOut);
  }, []);

  const value: SessionContextValue = React.useMemo(() => {
    const commonContextValues = {
      getCsrfToken,
      signOut,
    };

    if (loading) {
      return {
        ...commonContextValues,
        status: "loading",
        session: null,
      };
    } else if (session) {
      return {
        ...commonContextValues,
        status: "authenticated",
        session,
      };
    } else {
      return {
        ...commonContextValues,
        status: "unauthenticated",
        session: null,
      };
    }
  }, [session, loading]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}
