import { AuthContext } from "dashboard-common";
import { ListMembersSuccessResponse, OrganizationRole } from "identity-api";
import { Building2, Trash } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Avatar,
  AvatarFallback,
  BasicList,
  BreadCrumbs,
  Button,
  DocsLink,
  EmptyState,
  ErrorAlert,
  ItemPagination,
  ListElement,
  ListElementSkeleton,
  Sonner,
  Title,
  useDataFetcherState,
  useDocumentTitle,
} from "ui-components";
import { H2 } from "ui-components/src";
import { Link } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import DeleteMemberModal from "./DeleteMemberModal";
import EditMemberModal from "./EditMemberModal";

type MembersListViewProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function MembersListView({
  organizationId,
  organizationDisplayName,
}: MembersListViewProps) {
  const { getAccessToken, onUnauthorized, user } = useContext(AuthContext);
  const dataFetcher = useDataFetcherState<ListMembersSuccessResponse["body"]>();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "Members",
    },
  ];

  const fetchMembers = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listMembers({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    currentPage,
    organizationId,
    dataFetcher,
  ]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers, currentPage, itemsPerPage]);

  useDocumentTitle(`Members - ${organizationDisplayName}`);

  const userRoleForOrg = dataFetcher.data?.role;

  return (
    <>
      <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
      <div className="max-w-none relative mb-5">
        <Title>
          <div className="flex gap-2 items-baseline">
            <H2>Members</H2>
            <DocsLink path="/organizations/members" text="Docs" />
          </div>
        </Title>
      </div>
      <div className="w-full min-h-full mx-auto pb-20">
        <ItemPagination
          loading={dataFetcher.isLoading}
          className="pt-4"
          totalItems={dataFetcher.data?.totalResults || null} // total number of available items
          itemsPerPage={itemsPerPage}
          currentPage={currentPage} // initial page
          setPage={setCurrentPage} // callback to set the current page
        >
          <BasicList>
            {dataFetcher.error && (
              <ErrorAlert
                message={`There was a problem fetching the members: ${dataFetcher.error.message}`}
                action={{
                  text: "Retry",
                  cb: () => {
                    fetchMembers();
                  },
                }}
              />
            )}
            {dataFetcher.isLoading &&
              Array.from(Array(12).keys()).map((i) => (
                <ListElementSkeleton key={i} />
              ))}
            {dataFetcher.data?.members.map((member) => (
              <ListElement
                key={member.id}
                to={`/organizations/${organizationId}/members/${member.id}/`}
                LinkElement={Link}
              >
                <Avatar className="mr-4">
                  <AvatarFallback>
                    {member?.userInfo.displayName?.[0] ?? ""}
                  </AvatarFallback>
                </Avatar>
                <div className="flex-col flex text-sm basis-1/3 overflow-hidden">
                  Name:
                  <span className="text-xl font-semibold truncate">
                    {member.userInfo.displayName}
                  </span>
                </div>
                <div className="flex-col flex text-sm basis-1/3 overflow-hidden">
                  Email:
                  <span className="text-xl font-semibold truncate">
                    {member.userInfo.email}
                  </span>
                </div>
                <div className="flex-col flex text-sm">
                  Role:
                  <span className="text-xl font-semibold">{member.role}</span>
                </div>
                {userRoleForOrg === "admin" &&
                  user?.email !== (member?.userInfo?.email as string) && (
                    <div className="ml-auto flex gap-2 mr-4">
                      <EditMemberModal
                        member={member}
                        organizationId={organizationId}
                        onMemberUpdated={() => {
                          fetchMembers();
                          Sonner.toast("Member updated");
                        }}
                      />
                      <DeleteMemberModal
                        organizationId={organizationId}
                        memberId={member.id}
                        onMemberDeleted={() => {
                          fetchMembers();
                          Sonner.toast("Member deleted");
                        }}
                      >
                        <Button size="icon" className="rounded-lg">
                          <Trash />
                        </Button>
                      </DeleteMemberModal>
                    </div>
                  )}
              </ListElement>
            ))}
            {dataFetcher.data && !dataFetcher.data.members.length && (
              <EmptyState message="There are no members" />
            )}
          </BasicList>
        </ItemPagination>
      </div>
    </>
  );
}
