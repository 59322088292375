import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "dashboard-common";
import { PermissionMode, TeamPermission } from "identity-api";
import * as React from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "ui-components";
import * as z from "zod";

import { createIdentityServiceClient } from "../../../../IdentityServiceClient";
import {
  permissionResources,
  permissionResourceToDisplayName,
  permissionResourceType,
} from "../../permissionResources";
import SelectProjectInput from "../../SelectProjectInput/SelectProjectInput";

type CreateTeamPermissionModalProps = {
  teamId: string;
  organizationId: string;
  withinOrganizationId?: string;
  onTeamPermissionCreated: (project: TeamPermission) => void;
  disabled: boolean;
};

const createTeamPermission = (
  teamId: string,
  organizationId: string,
  projectId: string,
  resource: permissionResourceType,
  verb: PermissionMode,
  getAccessToken: () => Promise<string>,
  onUnauthorized: () => void,
) =>
  createIdentityServiceClient(getAccessToken, onUnauthorized)
    .createTeamPermission({
      parameters: {
        organizationId,
        teamId,
      },
      body: {
        projectId,
        permission: {
          resource,
          verb,
        },
      },
    })
    .then((response) => {
      if (response.code === 200) {
        return response.body;
      } else {
        console.error("Failed to create team permission");
        throw new Error(response.body.message);
      }
    });

export const formSchema = z.object({
  projectId: z.string().min(1, { message: "Project id is required" }),
  verb: z.string().min(1, { message: "Verb is required" }),
  resource: z.enum(permissionResources),
});

function CreateTeamPermissionModal({
  teamId,
  organizationId,
  onTeamPermissionCreated,
  disabled,
}: CreateTeamPermissionModalProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const Trigger = disabled ? TooltipTrigger : DialogTrigger;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <Trigger asChild>
          <Button
            className="rounded-lg !pointer-events-auto"
            disabled={disabled}
          >
            Create Team Permission
          </Button>
        </Trigger>
        {disabled && (
          <TooltipContent>
            <p>This action can only be performed by an Admin</p>
          </TooltipContent>
        )}
      </Tooltip>
      <DialogContent className={error ? "border-destructive" : ""}>
        <DialogHeader>
          <DialogTitle className={error ? "text-destructive" : ""}>
            Create Team Permission
          </DialogTitle>
        </DialogHeader>
        {open && (
          <CreateTeamPermissionModalForm
            teamId={teamId}
            organizationId={organizationId}
            onTeamPermissionCreated={onTeamPermissionCreated}
            error={error}
            setError={setError}
            setOpen={setOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function CreateTeamPermissionModalForm({
  teamId,
  organizationId,
  onTeamPermissionCreated,
  error,
  setError,
  setOpen,
}: Omit<CreateTeamPermissionModalProps, "disabled"> & {
  error: Error | null;
  setError: (error: Error | null) => void;
  setOpen: (open: boolean) => void;
}) {
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    disabled: isSubmitting,
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (error) {
      setError(null);
    }
    setIsSubmitting(true);

    try {
      const project = await createTeamPermission(
        teamId,
        organizationId,
        data.projectId,
        data.resource,
        data.verb as PermissionMode, //TODO - use selection rather than string input

        getAccessToken,
        onUnauthorized,
      );

      onTeamPermissionCreated(project);
      setOpen(false);
    } catch (e) {
      setError(e as Error);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="projectId"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="project-id">
                Project Id
              </Label>
              <SelectProjectInput
                value={field.value}
                onChange={(proId) => field.onChange(proId)}
                getAccessToken={getAccessToken}
                onUnauthorized={onUnauthorized}
                organizationId={organizationId}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="resource"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="resource">
                Resource
              </Label>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a resource type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {permissionResources.map((resource) => (
                    <SelectItem key={resource} value={resource}>
                      {permissionResourceToDisplayName(resource)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="verb"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Label className="block mb-2" htmlFor="verb">
                Verb
              </Label>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a verb" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="read">Read</SelectItem>
                  <SelectItem value="write">Write</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="mt-4">
          {error && (
            <p className="flex-1 text-destructive self-center">
              <div>There was a problem creating the project:</div>
              <div>{error.message}</div>
            </p>
          )}
          <Button
            disabled={!form.formState.isValid || isSubmitting}
            variant={error ? "destructive" : "default"}
          >
            {isSubmitting ? "Creating..." : error ? "Retry" : "Create"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}

export default CreateTeamPermissionModal;
