import { Session } from "dashboard-api";
export const dashboardServerURL = window.location.origin;

type GetSessionSuccess = {
  type: "success";
  session: Session;
};
type GetSessionError = {
  type: "error";
  error: Error;
};
type GetSessionResult = GetSessionSuccess | GetSessionError;

export async function getSession(): Promise<GetSessionResult> {
  const url = `${dashboardServerURL}/api/auth/session`;

  const requestInit: RequestInit = {
    method: "GET",
  };

  try {
    const res = await fetch(url, requestInit);
    if (!res.ok) {
      return {
        type: "error",
        error: new Error(`Failed to fetch session (${res.status})`),
      };
    }

    const data = await res.json();
    if (!data) {
      return {
        type: "error",
        error: new Error("Failed to fetch session - invalid response"),
      };
    }

    return {
      type: "success",
      session: data as Session,
    };
  } catch (e) {
    return {
      type: "error",
      error: e as Error,
    };
  }
}

type CSRFTokenSuccess = {
  type: "success";
  token: string;
};
type CSRFTokenError = {
  type: "error";
  error: Error;
};
export type CSRFTokenResult = CSRFTokenSuccess | CSRFTokenError;

export async function getCsrfToken(): Promise<CSRFTokenResult> {
  const url = `${dashboardServerURL}/api/auth/csrf`;

  try {
    const res = await fetch(url);
    if (!res.ok) {
      return {
        type: "error",
        error: new Error("Failed to fetch CSRF token"),
      };
    }

    const data = await res.json();
    if (typeof data !== "object" || !data["csrfToken"]) {
      return {
        type: "error",
        error: new Error("Failed to fetch CSRF token"),
      };
    }

    return {
      type: "success",
      token: data["csrfToken"],
    };
  } catch (e) {
    return {
      type: "error",
      error: e as Error,
    };
  }
}

type SignOutSuccess = {
  type: "success";
};
type SignOutError = {
  type: "error";
  error: Error;
};
export type SignOutResult = SignOutSuccess | SignOutError;

export async function signOut(): Promise<SignOutResult> {
  const csrfTokenResult = await getCsrfToken();
  if (csrfTokenResult.type === "error") {
    return {
      type: "error",
      error: csrfTokenResult.error,
    };
  }

  try {
    await fetch(`${dashboardServerURL}/api/auth/signout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        csrfToken: csrfTokenResult.token,
        callbackUrl: window.location.protocol + "//" + window.location.host,
      }),
    });

    return {
      type: "success",
    };
  } catch (e) {
    return {
      type: "error",
      error: e as Error,
    };
  }
}
