import { AuthContext } from "dashboard-common";
import { OrganizationRole, Team } from "identity-api";
import { Building2 } from "lucide-react";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import {
  BreadCrumbs,
  ErrorAlert,
  MenuBar,
  MenuItem,
  NotFound,
  useDataFetcherState,
} from "ui-components";
import { Link, Route, Switch, useLocation, useParams } from "wouter";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";
import { TeamInfo } from "./TeamInfo";
import { TeamMembersListView } from "./TeamMembersListView/TeamMembersListView";
import { TeamPermissionsListView } from "./TeamPermissionsListView/TeamPermissionsListView";

type TeamDashboardProps = {
  organizationId: string;
  organizationDisplayName: string;
  organizationRole?: OrganizationRole;
};

export function TeamDashboard({
  organizationId,
  organizationDisplayName,
  organizationRole,
}: TeamDashboardProps) {
  const [location] = useLocation();
  const splitLocation = location.split("/").filter((pathItem) => !!pathItem);
  const currentLocation = splitLocation[splitLocation.length - 1];

  const { teamId } =
    useParams<"/organizations/:organizationId/teams/:teamId">();
  const { getAccessToken, onUnauthorized } = useContext(AuthContext);

  const dataFetcher = useDataFetcherState<Team>();

  const fetchTeam = useCallback(() => {
    dataFetcher.setIsLoading(true);

    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .getTeam({
        parameters: {
          teamId,
          organizationId,
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError({
            message: response.body.message,
            code: response.code,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [getAccessToken, onUnauthorized, teamId, organizationId, dataFetcher]);

  useEffect(() => {
    fetchTeam();
  }, [fetchTeam]);

  const team = dataFetcher.data;

  const breadCrumbPath = [
    {
      name: (
        <div className="flex gap-2 items-center">
          <Building2 />
          {organizationDisplayName as string}
        </div>
      ),
      to: `/organizations/${organizationId}/`,
    },
    {
      name: "Teams",
      to: `/organizations/${organizationId}/teams/`,
    },
    {
      name: team?.name || "Team",
      to: `/organizations/${organizationId}/teams/${teamId}`,
    },
  ];

  if (["members", "permissions"].includes(currentLocation)) {
    breadCrumbPath.push({
      name: currentLocation[0].toUpperCase() + currentLocation.slice(1),
      to: "",
    });
  }

  const TeamMenu = () => (
    <MenuBar className="mb-10">
      <MenuItem
        active={!["members", "permissions"].includes(currentLocation)}
        to={`/organizations/${organizationId}/teams/${teamId}`}
        LinkElement={Link}
      >
        Info
      </MenuItem>
      <MenuItem
        active={currentLocation === "members"}
        to={`/organizations/${organizationId}/teams/${teamId}/members`}
        LinkElement={Link}
      >
        Members
      </MenuItem>
      <MenuItem
        active={currentLocation === "permissions"}
        to={`/organizations/${organizationId}/teams/${teamId}/permissions`}
        LinkElement={Link}
      >
        Permissions
      </MenuItem>
    </MenuBar>
  );

  return (
    <div className="w-full mt-10">
      {dataFetcher.error && (
        <div className="w-full relative">
          {dataFetcher.error.code === 404 ? (
            <ErrorAlert
              message="Team not found"
              link={{
                to: `/organizations/${organizationId}/teams`,
                text: "Go Back",
                element: Link,
              }}
            />
          ) : (
            <ErrorAlert
              message={dataFetcher.error.message}
              action={{
                text: "Retry",
                cb: () => fetchTeam(),
              }}
            />
          )}
        </div>
      )}
      {!dataFetcher.error && (
        <Switch>
          <Route path={"/organizations/:organizationId/teams/:teamId/"}>
            <>
              <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
              <TeamMenu />
              <TeamInfo
                teamId={teamId}
                organizationId={organizationId}
                organizationDisplayName={organizationDisplayName}
                onTeamUpdate={(team: Team) => {
                  dataFetcher.setData(team);
                }}
                team={team}
                loading={dataFetcher.isLoading}
                organizationRole={organizationRole!}
              />
            </>
          </Route>
          <Route path={"/organizations/:organizationId/teams/:teamId/members"}>
            <>
              <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
              <TeamMenu />
              <TeamMembersListView
                teamId={teamId}
                organizationId={organizationId}
                organizationDisplayName={organizationDisplayName}
                team={team}
                organizationRole={organizationRole!}
              />
            </>
          </Route>
          <Route
            path={"/organizations/:organizationId/teams/:teamId/permissions"}
          >
            <>
              <BreadCrumbs path={breadCrumbPath} LinkElement={Link} />
              <TeamMenu />
              <TeamPermissionsListView
                teamId={teamId}
                organizationId={organizationId}
                organizationDisplayName={organizationDisplayName}
                team={team}
                organizationRole={organizationRole!}
              />
            </>
          </Route>
          <Route path={"/organizations/:organizationId/teams/:teamId/*"}>
            {!dataFetcher.isLoading && <NotFound />}
          </Route>
        </Switch>
      )}
    </div>
  );
}
