import * as React from "react";
import { Link } from "wouter";

import { MSquaredSVG } from "../../MSquaredSVG";

export function LogoHeader() {
  return (
    <div className="flex justify-center">
      <div className="ml-2 flex-0">
        <Link to="/">
          <MSquaredSVG />
        </Link>
      </div>
    </div>
  );
}
