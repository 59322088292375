import { ListProjectsSuccessResponse } from "identity-api";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  buttonVariants,
  cn,
  DebouncedSearch,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ItemPagination,
  Skeleton,
  useDataFetcherState,
} from "ui-components";

import { createIdentityServiceClient } from "../../../IdentityServiceClient";

type SelectProjectInputProps = {
  value: string;
  onChange: (value: string) => void;
  getAccessToken: () => Promise<string>;
  onUnauthorized: () => void;
  organizationId: string;
};

const ITEMS_PER_PAGE = 10;

function SelectProjectInput({
  value,
  onChange,
  getAccessToken,
  onUnauthorized,
  organizationId,
}: SelectProjectInputProps) {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dataFetcher =
    useDataFetcherState<ListProjectsSuccessResponse["body"]>();
  const searchRef = React.useRef<HTMLInputElement>(null);

  const fetchProjects = useCallback(() => {
    dataFetcher.setIsLoading(true);
    createIdentityServiceClient(getAccessToken, onUnauthorized)
      .listProjects({
        parameters: {
          organizationId,
          offset: (currentPage - 1) * ITEMS_PER_PAGE,
          limit: ITEMS_PER_PAGE,
          search: searchRef.current?.value || "",
        },
        body: null,
      })
      .then((response) => {
        if (response.code === 200) {
          dataFetcher.setData(response.body);
        } else {
          dataFetcher.setError(response.body);
        }
      })
      .catch((err) => {
        console.error(err);
        dataFetcher.setError(err);
      });
  }, [
    getAccessToken,
    onUnauthorized,
    organizationId,
    dataFetcher,
    currentPage,
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const currentProject = dataFetcher.data?.projects.find(
    (organization) => organization.id === value,
  );

  const selectedProjectName = currentProject?.name;

  const handleProjectClick = (id: string) => {
    const organizationToSet = dataFetcher.data?.projects.find(
      (p) => id === p.id,
    );
    if (!organizationToSet) {
      return;
    }

    onChange(organizationToSet.id);
    setOpen(false);
  };

  if (dataFetcher.error) {
    return (
      <div
        className={cn(
          buttonVariants(),
          "min-h-0 h-10 w-full min-w-40 rounded-lg px-4 text-destructive",
        )}
      >
        There was a problem fetching the projects...
      </div>
    );
  }

  if (dataFetcher.isLoading && !open) {
    return (
      <div
        className={cn(
          buttonVariants(),
          "min-h-0 h-10 w-full min-w-40 rounded-lg px-4 opacity-30",
        )}
      >
        Loading...
      </div>
    );
  }

  if (!dataFetcher.isLoading && !open && dataFetcher.data?.totalResults === 0) {
    return (
      <div
        className={cn(
          buttonVariants(),
          "min-h-0 h-10 w-full min-w-40 rounded-lg px-4",
        )}
      >
        There are no projects available
      </div>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="min-h-0 h-10 w-full min-w-40 rounded-lg px-4">
          {selectedProjectName ?? "Please select a project..."}
          <ChevronDown />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Project</DialogTitle>
        </DialogHeader>
        <div>
          <DebouncedSearch
            onSearchChange={fetchProjects}
            placeholder="Filter by template name or id prefix (case-sensitive)..."
            className="mb-4"
            ref={searchRef}
          />
          <ItemPagination
            loading={dataFetcher.isLoading}
            className="relative"
            totalItems={dataFetcher.data?.totalResults || null} // total number of available items
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage} // initial page
            setPage={setCurrentPage} // callback to set the current page
            maxPagesToShow={5}
          >
            <ul className="flex flex-col gap-2 mb-4">
              {dataFetcher.data?.projects.map((project) => (
                <li key={project.id}>
                  <Button
                    key={project.id}
                    className="h-auto w-full border border-border flex flex-col items-start"
                    onClick={() => handleProjectClick(project.id)}
                  >
                    <span className="text-lg font-semibold">
                      {project.name}
                    </span>
                    <span>Project ID: {project.id}</span>
                  </Button>
                </li>
              ))}
              {!dataFetcher.data?.projects.length && !dataFetcher.isLoading && (
                <div className="text-center text-gray-500">
                  No projects found for your search
                </div>
              )}
              {dataFetcher.isLoading &&
                Array.from(Array(10).keys()).map((i) => (
                  <li key={i}>
                    <Skeleton className="min-h-0 h-10 w-full min-w-40 rounded-lg px-4" />
                  </li>
                ))}
              {dataFetcher.error && (
                <div className="text-center text-destructive">
                  There was a problem fetching the projects...
                </div>
              )}
            </ul>
          </ItemPagination>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SelectProjectInput;
